import React from 'react';
import CircularProgressOverlay from 'components/CircularProgressOverlay';
import MainLayout from '../layouts/mainLayout';
import LoginPage from '../components/Pages/login';

const LoginTemplate = () => (
  <MainLayout>
    <CircularProgressOverlay>
      <LoginPage />
    </CircularProgressOverlay>
  </MainLayout>
);

export default LoginTemplate;
